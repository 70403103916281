export class Contact {

    constructor(
      public name: string,
      public email: string,
      public message: string,
      public company?: string,
      public phoneNumber?: string,
      public captcha?: string
    ) {  }
}
