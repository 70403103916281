<section id="section-d56991b"
    class="fw-main-row section  ds section_padding_top_150 section_padding_bottom_150 columns_padding_15  "
    style="background-image:url(assets/images/footer_bg.png);">
    <h3 class="d-none"> Process</h3>
    <div class="top_corner_body"></div>
    <div class="container">
        <div class="row">
            <div id="column-91814e5" class="col-12 text-center left fw-column">
                <div class="fw-theme-steps">
                    <div class="fw-theme-step-wrap">
                        <div class="fw-step-left-part">
                            <h2 class="step-title color1">Strategy</h2>
                        </div>
                        <div class="fw-step-center-part">
                            <img width="600" height="600" src="assets/images/img_9-600x600.jpg"
                                class="attachment-dotdigital-square size-dotdigital-square" alt=""
                                srcset="assets/images/img_9-600x600.jpg 600w, assets/images/img_9-150x150.jpg 150w, assets/images/img_9-100x100.jpg 100w"
                                sizes="(max-width: 600px) 100vw, 600px"></div>
                        <div class="fw-step-right-part">
                            <p class="step-text">We define your competition and target audience. Discover what is
                                working in your online industry, then design your website accordingly.</p>
                        </div>
                    </div>
                    <div class="fw-theme-step-wrap">
                        <div class="fw-step-left-part">
                            <h2 class="step-title color2">Design</h2>
                        </div>
                        <div class="fw-step-center-part">
                            <img width="600" height="600" src="assets/images/img_3-600x600.jpg"
                                class="attachment-dotdigital-square size-dotdigital-square" alt=""
                                srcset="assets/images/img_3-600x600.jpg 600w, assets/images/img_3-150x150.jpg 150w, assets/images/img_3-100x100.jpg 100w"
                                sizes="(max-width: 600px) 100vw, 600px"></div>
                        <div class="fw-step-right-part">
                            <p class="step-text">Color scheme, layout, sitemap, and style. We will bring your brand to
                                life with a one of a kind masterpiece, built just for you.</p>
                        </div>
                    </div>
                    <div class="fw-theme-step-wrap">
                        <div class="fw-step-left-part">
                            <h2 class="step-title color3">Develop</h2>
                        </div>
                        <div class="fw-step-center-part">
                            <img width="600" height="600" src="assets/images/img_8-600x600.jpg"
                                class="attachment-dotdigital-square size-dotdigital-square" alt=""
                                srcset="assets/images/img_8-600x600.jpg 600w, assets/images/img_8-150x150.jpg 150w, assets/images/img_8-100x100.jpg 100w"
                                sizes="(max-width: 600px) 100vw, 600px"></div>
                        <div class="fw-step-right-part">
                            <p class="step-text">We turn your ideas into a reality. Your website is placed on a
                                "development server" where you get to watch the whole process, live.</p>
                        </div>
                    </div>
                    <div class="fw-theme-step-wrap">
                        <div class="fw-step-left-part">
                            <h2 class="step-title color4">Support</h2>
                        </div>
                        <div class="fw-step-center-part">
                        </div>
                        <div class="fw-step-right-part">
                            <p class="step-text">This is where you go live, to the world. We'll be at your side.</p>
                        </div>
                    </div>
                </div>
                <div class="fw-divider-space " style="margin-top: 60px;"></div>
                <a href="mailto:info@enligh10.com" target="_blank" class="wide_button theme_button inverse color1 large_height">
                    GET STARTED</a>
            </div>
        </div>
    </div>
    <div class="bottom_corner_body"></div>
</section>