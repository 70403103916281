import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { InViewportModule } from '@thisissoon/angular-inviewport';
import { FacebookModule } from '@jemys89/ngx-facebook';
import { HomeModule } from './home/home.module';
import { SharedModule } from './shared/shared.module';
import { RECAPTCHA_SETTINGS, RecaptchaSettings, RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
import { FormsModule } from '@angular/forms';
import { AboutUsModule } from './aboutus/aboutus.module';

import { AmplifyAngularModule, AmplifyService } from 'aws-amplify-angular';


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    NgbModule,
    InViewportModule,
    FacebookModule.forRoot(),
    HomeModule,
    AboutUsModule,
    SharedModule,
    RecaptchaModule,
    RecaptchaFormsModule
  ],
  providers: [{
    provide: RECAPTCHA_SETTINGS,
    useValue: {siteKey: '6LdzKusUAAAAAKCR_KMeIipMrnOmkyNtM567t-Mk' } as RecaptchaSettings
  },
  AmplifyService
],
  bootstrap: [AppComponent]
})
export class AppModule { }
