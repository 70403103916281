<div class="page_header_side page_header_side_special page_header_side_special-3 header_slide header_side_left ds"
    [ngClass]="{'active-slide-side-header' : sharedService.activeSlideSideHeader}">
    <div class="scroll-wrapper scrollbar-macosx" style="position: relative;">
        <div class="scrollbar-macosx scroll-content"
            style="height: 900px; margin-bottom: 0px; margin-right: 0px; max-height: none;">
            <div class="side_header_inner">
                <div class="toggle-wrap">
                    <span class="toggle_menu_side toggle_menu_side_special" (click)="toggleMenuSpecial()">
                        <span></span>
                        <span></span>
                        <span></span>
                    </span>
                </div><!-- eof .header_button -->
                <div class="widget widget_nav_menu">
                    <nav class="mainmenu_side_wrapper">
                        <ul id="menu-main-menu" class="nav menu-side-click">
                            <li id="menu-item-7971"
                                class="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-7894 current_page_item menu-item-7971">
                                <a routerLink="/">Home</a></li>
                            <!--<li id="menu-item-2765"
                                class="menu-item menu-item-type-post_type menu-item-object-page menu-item-2765">
                                <a [routerLink]='"."' [fragment]="about">About</a></li>-->
                            <!--<li id="menu-item-6106"
                                class="menu-item menu-item-type-post_type menu-item-object-page menu-item-6106"><a
                                    href="http://webdesign-finder.com/dotdigital-tech-lab/services/">Services</a></li>
                            <li id="menu-item-6304"
                                class="menu-item menu-item-type-taxonomy menu-item-object-fw-portfolio-category menu-item-6304">
                                <a href="http://webdesign-finder.com/dotdigital-tech-lab/portfolio/extended/">Works</a>
                            </li>
                            <li id="menu-item-212"
                                class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-212">
                                <a href="#">Pages</a>
                                <ul class="sub-menu">
                                    <li id="menu-item-7262"
                                        class="menu-item menu-item-type-post_type menu-item-object-page menu-item-7262">
                                        <a href="http://webdesign-finder.com/dotdigital-tech-lab/helpdesk/">Helpdesk</a>
                                    </li>
                                    <li id="menu-item-7271"
                                        class="menu-item menu-item-type-post_type menu-item-object-page menu-item-7271">
                                        <a href="http://webdesign-finder.com/dotdigital-tech-lab/our-story/">Our
                                            story</a></li>
                                    <li id="menu-item-7301"
                                        class="menu-item menu-item-type-post_type menu-item-object-page menu-item-7301">
                                        <a href="http://webdesign-finder.com/dotdigital-tech-lab/careers/">Careers</a>
                                    </li>
                                    <li id="menu-item-6757"
                                        class="menu-item menu-item-type-taxonomy menu-item-object-fw-team-category menu-item-6757">
                                        <a href="http://webdesign-finder.com/dotdigital-tech-lab/team/our-team-2/">Our
                                            team</a></li>
                                    <li id="menu-item-294"
                                        class="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-294">
                                        <a href="http://webdesign-finder.com/dotdigital-tech-lab/elements/">Elements</a>
                                        <ul class="sub-menu">
                                            <li id="menu-item-421"
                                                class="menu-item menu-item-type-post_type menu-item-object-page menu-item-421">
                                                <a
                                                    href="http://webdesign-finder.com/dotdigital-tech-lab/typography/">Typography</a>
                                            </li>
                                            <li id="menu-item-6130"
                                                class="menu-item menu-item-type-post_type menu-item-object-page menu-item-6130">
                                                <a
                                                    href="http://webdesign-finder.com/dotdigital-tech-lab/testimonials/">Testimonials</a>
                                            </li>
                                            <li id="menu-item-6740"
                                                class="menu-item menu-item-type-post_type menu-item-object-page menu-item-6740">
                                                <a href="http://webdesign-finder.com/dotdigital-tech-lab/faq-2/">FAQ</a>
                                            </li>
                                            <li id="menu-item-6179"
                                                class="menu-item menu-item-type-post_type menu-item-object-page menu-item-6179">
                                                <a
                                                    href="http://webdesign-finder.com/dotdigital-tech-lab/pricing/">Pricing</a>
                                            </li>
                                            <li id="menu-item-5742"
                                                class="menu-item menu-item-type-post_type menu-item-object-page menu-item-5742">
                                                <a
                                                    href="http://webdesign-finder.com/dotdigital-tech-lab/appointment/">Appointment</a>
                                            </li>
                                            <li id="menu-item-6597"
                                                class="menu-item menu-item-type-post_type menu-item-object-page menu-item-6597">
                                                <a href="http://webdesign-finder.com/dotdigital-tech-lab/our-process/">Our
                                                    Process</a></li>
                                        </ul>
                                        <span class="activate_submenu"></span>
                                    </li>
                                    <li id="menu-item-6229"
                                        class="menu-item menu-item-type-post_type_archive menu-item-object-fw-event menu-item-has-children menu-item-6229">
                                        <a href="http://webdesign-finder.com/dotdigital-tech-lab/event/">Events</a>
                                        <ul class="sub-menu">
                                            <li id="menu-item-6227"
                                                class="menu-item menu-item-type-post_type menu-item-object-page menu-item-6227">
                                                <a
                                                    href="http://webdesign-finder.com/dotdigital-tech-lab/calendar/">Calendar</a>
                                            </li>
                                            <li id="menu-item-6228"
                                                class="menu-item menu-item-type-post_type menu-item-object-fw-event menu-item-6228">
                                                <a
                                                    href="http://webdesign-finder.com/dotdigital-tech-lab/event/stet-clita-kasd-gubergren-sea-takimata/">Single
                                                    Event</a></li>
                                        </ul>
                                        <span class="activate_submenu"></span>
                                    </li>
                                    <li id="menu-item-5506"
                                        class="menu-item menu-item-type-post_type menu-item-object-page menu-item-5506">
                                        <a href="http://webdesign-finder.com/dotdigital-tech-lab/shop/">Shop</a></li>
                                    <li id="menu-item-6155"
                                        class="menu-item menu-item-type-post_type menu-item-object-page menu-item-6155">
                                        <a href="http://webdesign-finder.com/dotdigital-tech-lab/clients/">Clients</a>
                                    </li>
                                    <li id="menu-item-430"
                                        class="menu-item menu-item-type-custom menu-item-object-custom menu-item-430"><a
                                            href="http://webdesign-finder.com/dotdigital-tech-lab/404">404</a></li>
                                </ul>
                                <span class="activate_submenu"></span>
                            </li>
                            <li id="menu-item-6688"
                                class="menu-item menu-item-type-taxonomy menu-item-object-category menu-item-6688"><a
                                    href="http://webdesign-finder.com/dotdigital-tech-lab/category/blog-left-sidebar/">Blog</a>
                            </li>
                            <li id="menu-item-7093"
                                class="menu-item menu-item-type-post_type menu-item-object-page menu-item-7093"><a
                                    href="http://webdesign-finder.com/dotdigital-tech-lab/contact-4/">Contact</a></li>-->
                        </ul>
                    </nav>
                </div>
                <!--<div class="logo-meta text-center">
                    <span class="fontsize_24 white-text"><span class="highlight">1-800</span>-123-4567</span>
                </div>--><!-- eof logo-meta -->

            </div><!-- eof .side_header_inner -->
        </div>
        <div class="scroll-element scroll-x">
            <div class="scroll-element_outer">
                <div class="scroll-element_size"></div>
                <div class="scroll-element_track"></div>
                <div class="scroll-bar" style="width: 96px;"></div>
            </div>
        </div>
        <div class="scroll-element scroll-y" style="">
            <div class="scroll-element_outer">
                <div class="scroll-element_size"></div>
                <div class="scroll-element_track"></div>
                <div class="scroll-bar" style="height: 96px;"></div>
            </div>
        </div>
    </div>
</div>

<div class="page_header_side_fixed d-none d-sm-none d-md-flex">
    <span class="toggle_menu_side toggle_menu_side_special" (click)="toggleMenuSpecial()">
        <span></span>
        <span></span>
        <span></span>
    </span>
    <div class="page_social_icons">
        <span class="social-icons">
            <a href="https://www.facebook.com/#" class="social-icon socicon-facebook" target="_blank"></a>
            <a href="https://twitter.com/#" class="social-icon socicon-twitter" target="_blank"></a>
            <!--<a href="https://plus.google.com/#" class="social-icon socicon-google" target="_blank"></a>
                <a href="https://youtube.com/#" class="social-icon socicon-youtube" target="_blank"></a>
                <a href="https://vimeo.com/#" class="social-icon socicon-vimeo" target="_blank"></a>-->
        </span>
    </div><!-- eof social icons -->
</div>