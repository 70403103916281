<!--<div class="preloader">
  <div class="preloader_image"></div>
</div>-->


<!-- wrappers for visual page editor and boxed version of template -->
<div id="canvas">
  <div id="box_wrapper">
    <!-- template sections -->

	<app-sidebar></app-sidebar>

	<app-header></app-header>
	<router-outlet></router-outlet>
	
	<app-footer></app-footer>
	<app-copyright></app-copyright>
  </div>
  <!-- eof #box_wrapper -->
  <div class="fb-customerchat"
    attribution="setup_tool"
     page_id="302423209770004">
</div>
</div>

<!-- eof #canvas -->

<!-- Load Facebook SDK for JavaScript -->
<!--<div id="fb-root"></div>
<script>
  window.fbAsyncInit = function() {
    FB.init({
      xfbml            : true,
      version          : 'v7.0'
    });
  };

  (function(d, s, id) {
  var js, fjs = d.getElementsByTagName(s)[0];
  if (d.getElementById(id)) return;
  js = d.createElement(s); js.id = id;
  js.src = 'https://connect.facebook.net/en_US/sdk/xfbml.customerchat.js';
  fjs.parentNode.insertBefore(js, fjs);
}(document, 'script', 'facebook-jssdk'));</script>


<div class="fb-customerchat"
  attribution=setup_tool
  page_id="302423209770004"
theme_color="#0084ff">
</div>-->
