import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  activeSlideSideHeader = false;

  constructor() { }

  toggleMenuSpecial() {
    this.activeSlideSideHeader = !this.activeSlideSideHeader;
    return of(this.activeSlideSideHeader);
  }

}
