<section id="section-d96cd26" class="fw-main-row section  ds section_padding_top_0 section_padding_bottom_0 columns_padding_0 fullwidth-section horizontal-paddings-0  ">
    <h3 class="d-none"> Form</h3>
    <div class="top_corner_body"></div>
        <div class="container-fluid">
            <div class="row">
                <div id="column-1e0ba6b" class="col-12 text-left section-special center fw-column">
                    <div class="padding_80 left">
                        <div class="fw-divider-space  d-sm-none d-xs-none" style="margin-top: 75px;"></div>

                        <div class="fw-divider-space  d-lg-none d-md-none d-xs-none" style="margin-top: 30px;"></div>
                        <div class="special-heading text-left">
                            <h2 class="section_header margin_0 big_title ">
                            <span class=" medium text-transform-none">
                                Contact Us	</span>
                            </h2>
                        </div>

                        <div class="fw-divider-space " style="margin-top: 45px;"></div>
                        <div class="form-wrapper  text-left fields_with_background columns_padding_10">
                            <form (ngSubmit)="onSubmit()" #contactForm="ngForm" >
                                <div class="wrap-forms">
                                    <div class="row"></div>
                                    <div class="row">
                                        <div class="col-12 col-md-6 form-builder-item">
                                            <div class="form-group has-placeholder text-center">
                                                <label for="name">Name<sup>*</sup></label>
                                                <input [(ngModel)]="model.name" class="form-control text-center" type="text" name="name" placeholder="Name" id="name" required>
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-6 form-builder-item">
                                            <div class="form-group has-placeholder text-center">
                                                <label for="company">Company<sup>*</sup></label>
                                                <input [(ngModel)]="model.company" class="form-control text-center" type="text" name="company" placeholder="Company" id="company">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12 col-md-6 form-builder-item">
                                            <div class="form-group has-placeholder text-center">
                                                <label for="email">Email<sup>*</sup></label>
                                                <input [(ngModel)]="model.email" class="form-control text-center" type="text" name="email" placeholder="Email" id="email" required>
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-6 form-builder-item">
                                            <div class="form-group has-placeholder text-center">
                                                <label for="phoneNumber">Phone number<sup>*</sup></label>
                                                <input [(ngModel)]="model.phoneNumber" class="form-control text-center" type="text" name="phoneNumber" placeholder="Phone number" id="phoneNumber">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12 form-builder-item">
                                            <div class="form-group has-placeholder text-center">
                                                <label for="message">Your Message<sup>*</sup></label>
                                                <textarea [(ngModel)]="model.message" class="form-control text-center" rows="6" name="message" placeholder="Your Message" id="message" required></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12 form-builder-item">
                                            <div class="form-group">
                                                <re-captcha
                                                [(ngModel)]="model.captcha"
                                                name="captcha"
                                                required
                                                #captchaControl="ngModel"
                                                ></re-captcha>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row"></div>
                                    </div>
                                    <div class="wrap-forms wrap-forms-buttons topmargin_20">
                                        <div class="row">
                                            <div class="col-sm-12 wrap-buttons text-left">
                                                <button class="theme_button wide_button color1 large_height" type="submit" [ngClass]="{'disabled': !contactForm.form.valid }" [disabled]="!contactForm.form.valid">Send Message</button>
                                                <button class="theme_button wide_button color1 large_height" type="reset" (click)="clearForm();contactForm.reset()">Clear</button>
                                            </div>
                                        </div>
                                    </div>
                            </form>
                        </div>
                        <div class="fw-divider-space  hidden-sm hidden-xs" style="margin-top: 60px;"></div>
                        <div class="fw-divider-space  hidden-lg hidden-md hidden-xs" style="margin-top: 30px;"></div>
                    </div>
                </div>                      
            </div>
        </div>
    <div class="bottom_corner_body"></div>
</section>
