<header id="header" class="main-header-wrap transparent_wrapper header-13 ">
    <div class="page_header_wrapper affix-wrapper" style="height: 124px;">
        <div class="page_header toppadding_30 bottompadding_30 ds toggler_xs_right fixed-top">
        <div class="container">
            <div class="row">
                <div class="col-xs-12 display_table">
                    <div class="header_left_logo display_table_cell">
                        <a href="https://enligh10.com" rel="home" class="logo logo_image_and_text">
                            <img src="/assets/images/enligh10-logo-white-sm.png" alt="enligh10">
                        </a>
                    </div>
                    <div class="header_right_buttons display_table_cell text-right d-md-none d-lg-none">
                        <span class="toggle_menu_side toggle_menu_side_special" (click)="toggleMenuSpecial()"><span></span></span>
                    </div>
                    <div class="header_right_buttons dropdown-wrap header_right_buttons display_table_cell d-none d-sm-none d-md-table-cell">
                        <a href="mailto:info@enligh10.com" target="_blank" class="wide_button theme_button inverse color1 medium_height">
                            Request a Quote
                        </a>
                    </div>
                </div><!--	eof .col-sm-* -->
            </div><!--	eof .row-->
        </div> <!--	eof .container-->
    </div></div><!-- eof .page_header -->
</header>
