<footer id="footer"
        class="page_footer footer-4 section_padding_top_50 section_padding_bottom_50 columns_padding_15 background_cover ds light"
        style="background-image: url(/assets/images/img_125.jpg)">
        <div class="container">

                <div class="row">
                        <div class="col-xs-12 col-md-4 to_animate animated fadeInUp" data-animation="fadeInUp">
                                <div class="fw-divider-space " style="margin-top: 12px;"></div>
                                <div id='about'
                                        class="widget-odd widget-last widget-first widget-1 special-title widget widget_text">
                                        <h3 class="widget-title">About</h3>
                                        <div class="textwidget">
                                                <p>We work with companies & founders to bring your best ideas to life. Our mission is to bring value to your company solving your most difficult problems by architecting, designing or building your next digital solution.</p>
                                        </div>
                                </div>
                                <div class="divider_95 visible-lg visible-md "></div>
                        </div>
                        <div class="col-xs-12 col-md-4 to_animate animated fadeInUp" data-animation="fadeInUp">
                                <div class="fw-divider-space " style="margin-top: 12px;"></div>
                                <div id="dotdigital_socials_2-2"
                                        class="widget-odd widget-last widget-first widget-1 special-title widget widget_socials_2">
                                        <h3 class="widget-title">Social</h3>
                                        <div
                                                class="widget-socials-2 no-bullets no-top-border no-bottom-border topmargin_0">
                                                <ul class="social-list">
                                                        <li class="list-item">
                                                                <a href="https://facebook.com/nligh10" target="_blank"
                                                                        class="social-icon color-bg-icon rounded-icon  social-icon soc-facebook"></a>
                                                                <a href="https://facebook.com/nligh10" target="_blank"
                                                                        class="icon-title">Facebook</a>
                                                        </li>                                                                                                         
                                                </ul>
                                        </div>
                                </div>
                                <div class="divider_95 visible-lg visible-md "></div>
                        </div>
                        <div class="col-xs-12 col-md-4 to_animate animated fadeInUp" data-animation="fadeInUp">
                                <div class="fw-divider-space " style="margin-top: 12px;"></div>
                                <div id="dotdigital_icons_list-3"
                                        class="widget-odd widget-last widget-first widget-1 special-title type-2 widget widget_icons_list">
                                        <h3 class="widget-title">Contact</h3>
                                        <div class="widget-icons-list no-bullets no-top-border no-bottom-border">                                                
                                                <div class="media small-teaser inline-block margin_0">
                                                        <div class="media-left">
                                                                <i class="fa fa-envelope-o highlight"></i>
                                                        </div>
                                                        <div class="media-body darklinks">
                                                                <p>13790 Bridgewater Crossings Blvd, STE 1080, Windermere, FL 34786</p>        
                                                        </div>
                                                </div>
                                                <div class="media small-teaser inline-block margin_0">
                                                        <div class="media-left">
                                                                <i class="fa fa-envelope highlight"></i>
                                                        </div>
                                                        <div class="media-body darklinks">
                                                                <a href="mailto:info@enligh10.com">info@enligh10.com</a>
                                                        </div>
                                                </div>
                                        </div>
                                </div>
                                <div class="divider_95 visible-lg visible-md "></div>
                        </div>
                </div>

        </div>
</footer>