import { Component, OnInit } from '@angular/core';
import { FacebookService, InitParams } from '@jemys89/ngx-facebook';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'enligh10-web-cs';
  animate = false;
  constructor(private facebookService: FacebookService) { }

  ngOnInit(): void {
    this.initFacebookService();
  }

  private initFacebookService(): void {
    const initParams: InitParams = { xfbml: true, version: 'v7.0'};
    this.facebookService.init(initParams);
  }
  onInViewportChange(inViewport: boolean){
    this.animate = inViewport;
  }
}
