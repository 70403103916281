import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';




const routes: Routes = [
  { path: 'home',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'about',
    loadChildren: () => import('./shared/shared.module').then(m => m.SharedModule)
  },
  { path: 'about-us',
    loadChildren: () => import('./aboutus/aboutus.module').then(m => m.AboutUsModule)
  },
  { path: '', redirectTo: '/home', pathMatch: 'full' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes )
],
  exports: [RouterModule]
})
export class AppRoutingModule { }
