import { Component, OnInit } from '@angular/core';
import { SharedService } from '../../services/shared.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  activeSlideSideHeader = false;
  constructor(public sharedService: SharedService) { }

  ngOnInit(): void {
  }

  toggleMenuSpecial(): void {
    this.sharedService.toggleMenuSpecial().subscribe(x => this.activeSlideSideHeader = x);
  }
}
