import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CopyrightComponent } from './components/copyright/copyright.component';
import { ProcessComponent } from './components/process/process.component';
import { AboutComponent } from './components/about/about.component';
import { ContactComponent } from './components/contact/contact.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { RouterModule } from '@angular/router';
import { SharedRoutingModule } from './shared-routing.module';



@NgModule({
  declarations: [
    AboutComponent,
    ContactComponent,
    CopyrightComponent,
    FooterComponent,
    HeaderComponent,
    ProcessComponent,
    SidebarComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    SharedRoutingModule
  ],
  exports: [
    CopyrightComponent, ProcessComponent, AboutComponent, ContactComponent,
    FooterComponent, HeaderComponent, SidebarComponent, FormsModule
  ]
})
export class SharedModule { }
