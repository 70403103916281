<div class="fw-page-builder-content">
    <section class="fw-main-row section ls section_padding_top_0 section_padding_bottom_0 columns_padding_15 overlay" style="background: url('/assets/images/img_126.jpg') no-repeat 50% 50%; -webkit-background-size: cover; -moz-background-size: cover; -o-background-size: cover; background-size: cover; ">
        <div class="container">
            <div class="row">
                <div class="col d-flex align-items-start align-items-md-center">
                    <!--<div class="layer-text">
                        High Tech	
                    </div>-->
                    <div class="rs-group-wrap">
                        <div class="rs-wrap-text" >
                            <div style="position:absolute;display:block;">
                                <div style="position: absolute; display: block; transform-origin: 50% 50% 0px; transform: matrix(1, 0, 0, 1, 0, 0); visibility: visible; overflow: hidden;">
                                    <div class="rs-layer layer-text" >
                                        High Tech	
                                    </div>
                                </div>
                            </div>
                        </div>	
                        <div class="rs-wrap-text-bold">
                            <div style="position:absolute;display:block;">
                                <div style="position: absolute; display: block; transform-origin: 50% 50% 0px; transform: matrix(1, 0, 0, 1, 0, 0); visibility: visible; overflow: hidden;">
                                    <div class="rs-layer layer-text-bold" >
                                        Lab
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--<div class="layer-text-bold">Lab</div>-->
                        <div class="rs-supported-text">
                            <div style="position:absolute;display:block;">
                                <div style="position: absolute; display: block; transform-origin: 50% 50% 0px; transform: matrix(1, 0, 0, 1, 0, 0); visibility: visible; overflow: hidden;">
                                    <div id="slider-14-slide-55-layer-9" class="rs-layer" >
                                        We are supported by our audience.
                                    </div>
                                </div>
                            </div>
                        </div>					
                        <div class="rs-line">
                            <div style="position:absolute;display:block;">
                                <div style="position: absolute; display: block; visibility: visible; overflow: hidden; transform-origin: 50% 50% 0px; transform: matrix(1, 0, 0, 1, 0, 0);">
                                    <div id="slider-14-slide-53-layer-10" style="z-index: 9; background-color: rgb(255, 255, 255); font-family: Roboto; visibility: visible; overflow: hidden; border-color: #e89501; border-style: none; margin: 0px; border-radius: 0px; padding: 0px; color: rgb(255, 255, 255); width: 150px; height: 2px; min-height: 0px; min-width: 0px; max-height: none; max-width: none; opacity: 1; transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform-origin: 50% 50% 0px;" class="rs-layer d-none d-sm-none d-md-block"> 
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="rs-oval">
                            <div style="position:absolute;display:block;">
                                <div style="position: absolute; display: block; visibility: visible; overflow: visible; transform-origin: 50% 50% 0px; transform: matrix(1, 0, 0, 1, 0, 0);">
                                    <div id="slider-14-slide-53-layer-11" class="rev-btn rs-layer d-none d-sm-none d-md-block" data-type="button"> 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="forcer"></div>
                </div>
                
                
            </div>
            
        </div>
    </section>
    <app-process></app-process>
</div>
