import { Component, OnInit, OnDestroy } from '@angular/core';
import { SharedService } from '../../services/shared.service';
import { Subject } from 'rxjs';


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit, OnDestroy {
  private readonly onDestroy = new Subject<void>();
  activeSlideSideHeader = false;

  constructor(public sharedService: SharedService) { }

  ngOnInit(): void {
  }

  ngOnDestroy(){
    this.onDestroy.next();
  }

  toggleMenuSpecial() {
    this.sharedService.toggleMenuSpecial()
    .subscribe(x => this.activeSlideSideHeader = x);
  }

  /*toggleMenuSpecial(){
      this.activeSlideSideHeader = !this.activeSlideSideHeader;

    if (hasHeaderSlide || hasToggleSpecial) {
      $sideHeader.toggleClass('active-slide-side-header');
  } else {
      if ($thisToggler.parent().hasClass('header_side_right')) {
          $body.toggleClass('active-side-header slide-right');
      } else {
          $body.toggleClass('active-side-header');
      }
  }
  }*/
}
